import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { LuBoxes, LuCar } from 'react-icons/lu';

import { getInventory, getInventoryOverview } from 'actions/inventoryActions';
import history from 'helpers/history';
import InventoryItem from 'models/InventoryItemModel';
import InventoryModal from 'components/modals/InventoryModal';
import axios from 'helpers/axios';
import config from 'helpers/config';
import InventoryOverviewModel from 'models/InventoryOverviewModel';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';
import TableColumn from 'models/TableColumnModel';
import InventoryList from './InventoryList';
import LoanerCarsTab from './loaners/LoanerCarsTab';

const { apiUrl } = config;

interface ComponentState {
    errorMessage: string | null;
    searchPhrase: string;
    page: number;
    itemsPerPage: number;
    isCreateInventoryItemModalOpen: boolean;
    isEditInventoryItemModalOpen: boolean;
    editableInventoryItemId: string | null;
    tableColumns: TableColumn[];
    activeTab: number;
    [x: string]: any;
}

interface Props {
    inventory: InventoryItem[];
    inventoryLoading: boolean;
    overview: InventoryOverviewModel;
    dispatch: Dispatch;
}

class InventoryListPage extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        this.state = {
            errorMessage: null,
            searchPhrase: '',
            page: 1,
            itemsPerPage: 30,
            inventoryItemsCount: 0,
            editableInventoryItemId: null,
            isCreateInventoryItemModalOpen: false,
            isEditInventoryItemModalOpen: false,
            tableColumns: inventoryListColumns,

            activeTab: 0,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        this.setState(
            {
                page: 0,
                itemsPerPage: 50,
                searchPhrase: '',
            },
            this.getInventory,
        );
        dispatch<any>(getInventoryOverview());
    }

    getInventory() {
        const { dispatch } = this.props;
        const { page, itemsPerPage, searchPhrase } = this.state;
        dispatch<any>(getInventory({ page, itemsPerPage, searchPhrase }));
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;
        this.setState(
            {
                [name]: value,
            },
            this.getInventory,
        );
    }

    handleSearch(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;
        this.setState(
            {
                [name]: value,
            },
            this.getInventory,
        );
    }

    goToInventoryDetails(inventoryItemId: string) {
        history.push(`/inventory/${inventoryItemId}`);
    }

    goToSupplierDetails(supplierId: string, e: any) {
        e.stopPropagation();
        history.push(`/supplier/${supplierId}`);
    }

    editInventoryItem(inventoryItemId: string) {
        this.setState({ isEditInventoryItemModalOpen: true, editableInventoryItemId: inventoryItemId });
    }

    deleteInventoryItem(inventoryItem: InventoryItem) {
        displayConfirmationModal(`${inventoryItem.description}`)
            .then(() => {
                axios
                    .delete(`${apiUrl}/inventory/${inventoryItem.id}`)
                    .then(() => {
                        this.componentDidMount();
                    })
                    .catch((err) => console.log(err));
            })
            .catch((_) => _);
    }

    modalClosed(inventoryItemCreated?: boolean, inventoryItemUpdated?: boolean) {
        this.setState({
            isCreateInventoryItemModalOpen: false,
            isEditInventoryItemModalOpen: false,
            editableInventoryItemId: null,
        });
        if (inventoryItemCreated || inventoryItemUpdated) {
            this.componentDidMount();
        }
    }

    getInventoryModal() {
        const { isCreateInventoryItemModalOpen, isEditInventoryItemModalOpen, editableInventoryItemId } = this.state;
        if (isEditInventoryItemModalOpen && !!editableInventoryItemId) {
            return (
                <InventoryModal
                    inventoryItemId={editableInventoryItemId}
                    onClose={(inventoryItemCreated?: boolean, inventoryItemUpdated?: boolean) =>
                        this.modalClosed(inventoryItemCreated, inventoryItemUpdated)
                    }
                ></InventoryModal>
            );
        } else if (isCreateInventoryItemModalOpen) {
            return (
                <InventoryModal
                    onClose={(inventoryItemCreated?: boolean, inventoryItemUpdated?: boolean) =>
                        this.modalClosed(inventoryItemCreated, inventoryItemUpdated)
                    }
                ></InventoryModal>
            );
        }
    }

    isColumnActive(columnName: string) {
        return this.state.tableColumns.find((column) => column.name === columnName)?.active;
    }

    getTabs() {
        return [
            {
                title: (
                    <span className="flex ">
                        <span>Inventory List</span>
                        <span className="ml-1.5">
                            <LuBoxes className="mt-1.5" />
                        </span>
                    </span>
                ),
                component: <InventoryList />,
            },
            {
                title: (
                    <span className="flex">
                        <span>Loaner Cars</span>
                        <span className="ml-1.5">
                            <LuCar className="mt-1.5" />
                        </span>
                    </span>
                ),
                component: <LoanerCarsTab />,
            },
        ];
    }

    render() {
        const { activeTab } = this.state;
        /* tslint:disable */
        // @ts-ignore: no-unused-vars
        /* tslint:disable-next-line */
        const tabs = this.getTabs();

        return (
            <div className="list-page">
                <h2 className="list-page__title">Inventory</h2>
                <div className="mt-8">
                    {tabs.map((tab, index) => {
                        return (
                            <div
                                className={`inline-flex px-5 border-b-4 ${
                                    activeTab === index ? ' border-blue-500 ' : '  border-gray-500 hover:text-blue-400'
                                }`}
                                key={index}
                            >
                                <span
                                    className={`clickable text-lg ${
                                        activeTab === index
                                            ? 'font-bold text-blue-500 border-blue-500 '
                                            : ' font-medium text-gray-400 border-gray-500 hover:text-blue-400'
                                    }`}
                                    onClick={() => this.setState({ activeTab: index })}
                                >
                                    {tab.title}
                                </span>
                            </div>
                        );
                    })}
                </div>
                {tabs[activeTab].component}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    inventory: state.inventory.inventoryList,
    inventoryLoading: state.inventory.inventoryListLoading,
    overview: state.inventory.inventoryOverview,
});

export default connect(mapStateToProps)(InventoryListPage);

const inventoryListColumns: TableColumn[] = [
    {
        name: 'description',
        active: true,
        text: 'Description',
        sortable: true,
    },
    {
        name: 'itemCode',
        active: true,
        text: 'Item Code',
        sortable: true,
    },
    {
        name: 'status',
        active: true,
        text: 'Status',
        sortable: false,
    },
    {
        name: 'brand',
        active: true,
        text: 'Brand',
        sortable: true,
    },
    {
        name: 'supplier',
        active: true,
        text: 'Supplier',
        sortable: true,
    },
    {
        name: 'cost',
        active: true,
        text: 'Cost',
        sortable: true,
    },
    {
        name: 'retails',
        active: true,
        text: 'Retail Price',
        sortable: true,
    },
];
