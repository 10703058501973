import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { getInventory, getInventoryOverview } from 'actions/inventoryActions';
import history from 'helpers/history';
import InventoryItem from 'models/InventoryItemModel';
import placeholders from 'helpers/placeholders';
import InventoryModal from 'components/modals/InventoryModal';
import axios from 'helpers/axios';
import config from 'helpers/config';
import InventoryOverviewModel from 'models/InventoryOverviewModel';
import { FaDollarSign, FaEdit, FaRegTrashAlt, FaSort } from 'react-icons/fa';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';
import TableColumn from 'models/TableColumnModel';
import ColumnsPicker from 'components/common/ColumnsPicker';
import Price from 'components/common/Price';

const { apiUrl } = config;

interface ComponentState {
    errorMessage: string | null;
    searchPhrase: string;
    page: number;
    itemsPerPage: number;
    isCreateInventoryItemModalOpen: boolean;
    isEditInventoryItemModalOpen: boolean;
    editableInventoryItemId: string | null;
    tableColumns: TableColumn[];
    [x: string]: any;
}

interface Props {
    inventory: InventoryItem[];
    inventoryLoading: boolean;
    overview: InventoryOverviewModel;
    dispatch: Dispatch;
}

class InventoryList extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        this.state = {
            errorMessage: null,
            searchPhrase: '',
            page: 1,
            itemsPerPage: 30,
            inventoryItemsCount: 0,
            editableInventoryItemId: null,
            isCreateInventoryItemModalOpen: false,
            isEditInventoryItemModalOpen: false,
            tableColumns: inventoryListColumns,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        this.setState(
            {
                page: 0,
                itemsPerPage: 50,
                searchPhrase: '',
            },
            this.getInventory,
        );
        dispatch<any>(getInventoryOverview());
    }

    getInventory() {
        const { dispatch } = this.props;
        const { page, itemsPerPage, searchPhrase } = this.state;
        dispatch<any>(getInventory({ page, itemsPerPage, searchPhrase }));
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;
        this.setState(
            {
                [name]: value,
            },
            this.getInventory,
        );
    }

    handleSearch(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;
        this.setState(
            {
                [name]: value,
            },
            this.getInventory,
        );
    }

    goToInventoryDetails(inventoryItemId: string) {
        history.push(`/inventory/${inventoryItemId}`);
    }

    goToSupplierDetails(supplierId: string, e: any) {
        e.stopPropagation();
        history.push(`/supplier/${supplierId}`);
    }

    editInventoryItem(inventoryItemId: string) {
        this.setState({ isEditInventoryItemModalOpen: true, editableInventoryItemId: inventoryItemId });
    }

    deleteInventoryItem(inventoryItem: InventoryItem) {
        displayConfirmationModal(`${inventoryItem.description}`)
            .then(() => {
                axios
                    .delete(`${apiUrl}/inventory/${inventoryItem.id}`)
                    .then(() => {
                        this.componentDidMount();
                    })
                    .catch((err) => console.log(err));
            })
            .catch((_) => _);
    }

    modalClosed(inventoryItemCreated?: boolean, inventoryItemUpdated?: boolean) {
        this.setState({
            isCreateInventoryItemModalOpen: false,
            isEditInventoryItemModalOpen: false,
            editableInventoryItemId: null,
        });
        if (inventoryItemCreated || inventoryItemUpdated) {
            this.componentDidMount();
        }
    }

    getInventoryModal() {
        const { isCreateInventoryItemModalOpen, isEditInventoryItemModalOpen, editableInventoryItemId } = this.state;
        if (isEditInventoryItemModalOpen && !!editableInventoryItemId) {
            return (
                <InventoryModal
                    inventoryItemId={editableInventoryItemId}
                    onClose={(inventoryItemCreated?: boolean, inventoryItemUpdated?: boolean) =>
                        this.modalClosed(inventoryItemCreated, inventoryItemUpdated)
                    }
                ></InventoryModal>
            );
        } else if (isCreateInventoryItemModalOpen) {
            return (
                <InventoryModal
                    onClose={(inventoryItemCreated?: boolean, inventoryItemUpdated?: boolean) =>
                        this.modalClosed(inventoryItemCreated, inventoryItemUpdated)
                    }
                ></InventoryModal>
            );
        }
    }

    isColumnActive(columnName: string) {
        return this.state.tableColumns.find((column) => column.name === columnName)?.active;
    }

    render() {
        const { isCreateInventoryItemModalOpen, isEditInventoryItemModalOpen, tableColumns } = this.state;
        const { overview } = this.props;
        return (
            <div className="list-page">
                <h2 className="list-page__title">Inventory</h2>
                {!!overview && (
                    <div className="sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 grid grid-cols-3">
                        <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border">
                            <div className="flex">
                                <div className="rounded-lg bg-green-500 p-4 inline-block"></div>
                                <div className="w-3/4 text-center">
                                    <div>
                                        <span>Total quantity</span>
                                    </div>
                                    <div>
                                        <span className="text-xl font-bold">{overview.count}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border">
                            <div className="flex">
                                <div className="rounded-lg bg-green-500 p-4 inline-block">
                                    <FaDollarSign className=" text-white text-xl" />
                                </div>
                                <div className="w-3/4 text-center">
                                    <div>
                                        <span>Total Cost</span>
                                    </div>
                                    <div>
                                        <span className="text-xl font-bold">
                                            <Price price={overview.totalCost} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border">
                            <div className="flex">
                                <div className="rounded-lg bg-green-500 p-4 inline-block">
                                    <FaDollarSign className=" text-white text-xl" />
                                </div>
                                <div className="w-3/4 text-center">
                                    <div>
                                        <span>Total Value</span>
                                    </div>
                                    <div>
                                        <span className="text-xl font-bold">
                                            <Price price={overview.totalValue} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {isEditInventoryItemModalOpen || isCreateInventoryItemModalOpen ? this.getInventoryModal() : null}

                <div className="search-add-row items-center mt-3">
                    <input
                        onChange={this.handleSearch}
                        name="searchPhrase"
                        placeholder={placeholders.INVENTORY_SEARCH}
                        className="bg-white placeholder  placeholder-gray-400 placeholder-font-bold rounded-xl border border-green-500 px-2 py-1 font-medium w-1/3 focus:ring-1 focus:outline-none focus:border-blue-500"
                    ></input>

                    <button
                        className="bg-green-600 py-1 px-2 rounded-2xl font-medium text-base ml-auto mb-4"
                        onClick={() =>
                            this.setState({ isCreateInventoryItemModalOpen: !isCreateInventoryItemModalOpen })
                        }
                    >
                        + Add new
                    </button>
                </div>
                <div className="flex items-center my-1">
                    <div className="ml-auto">
                        <ColumnsPicker
                            onChange={(updatedColumns) => this.setState({ tableColumns: updatedColumns })}
                            columns={tableColumns}
                        />
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table className="items-center w-full ">
                        <thead>
                            <tr className="bg-blue-500 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                {tableColumns.map(
                                    (column, index) =>
                                        column.active && (
                                            <th key={index} className={`pl-6 py-3 ${index === 0 && 'rounded-tl-md'}`}>
                                                <span className="clickable">
                                                    <span>{column.text}</span>
                                                    {!!column.sortable && <FaSort className="inline ml-1" />}
                                                </span>
                                            </th>
                                        ),
                                )}
                                <th className="px-6 py-3 rounded-tr-md">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.props.inventory?.map((inventoryItem, index) => (
                                <tr
                                    className="border-b font-semibold hover:bg-blue-200 clickable bg-white border-r-2 border-l-2"
                                    key={index}
                                    onClick={() => this.goToInventoryDetails(inventoryItem.id)}
                                >
                                    {this.isColumnActive('description') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3 ">
                                            <span>{inventoryItem.description}</span>
                                        </td>
                                    )}

                                    {this.isColumnActive('itemCode') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3">
                                            <span>{inventoryItem.itemCode}</span>
                                        </td>
                                    )}

                                    {this.isColumnActive('status') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3">
                                            {!!inventoryItem.quantity && (
                                                <span
                                                    style={{ backgroundColor: 'gray' }}
                                                    className="mr-2 px-1 py-1 rounded-lg hover:opacity-80 text-xs"
                                                >
                                                    <span className="font-semibold text-white inline-flex whitespace-nowrap">
                                                        {inventoryItem.quantity} on hand
                                                    </span>
                                                </span>
                                            )}

                                            {!!inventoryItem.quantityInProgress && (
                                                <span
                                                    style={{ backgroundColor: 'red' }}
                                                    className="mr-2 px-1 py-1 rounded-lg hover:opacity-80 text-xs"
                                                >
                                                    <span className="font-semibold text-white inline-flex whitespace-nowrap">
                                                        {inventoryItem.quantityInProgress} in progress
                                                    </span>
                                                </span>
                                            )}
                                        </td>
                                    )}

                                    {this.isColumnActive('brand') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3">
                                            <span>{inventoryItem.brand}</span>
                                        </td>
                                    )}

                                    {this.isColumnActive('supplier') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3">
                                            <span
                                                className="link"
                                                onClick={(e) => this.goToSupplierDetails(inventoryItem.supplier?.id, e)}
                                            >
                                                {inventoryItem.supplier?.companyName}
                                            </span>
                                        </td>
                                    )}

                                    {this.isColumnActive('cost') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3">
                                            <Price price={inventoryItem.costCents / 100} />
                                        </td>
                                    )}
                                    {this.isColumnActive('retail') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3 non-clickable">
                                            <Price price={inventoryItem.retailPriceCents / 100} />
                                        </td>
                                    )}

                                    <td className="px-6 align-middle whitespace-nowrap p-3 non-clickable">
                                        <FaRegTrashAlt
                                            size={15}
                                            className="inline clickable mr-1"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.deleteInventoryItem(inventoryItem);
                                            }}
                                        />
                                        <FaEdit
                                            size={15}
                                            className="inline clickable"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.editInventoryItem(String(inventoryItem.id));
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {this.props.inventory?.length < 1 && (
                        <div className="py-8 flex justify-center border-b-2 border-l-2 border-r-2 rounded-b-lg bg-white">
                            <h2 className="text-lg font-semibold">No inventory found</h2>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    inventory: state.inventory.inventoryList,
    inventoryLoading: state.inventory.inventoryListLoading,
    overview: state.inventory.inventoryOverview,
});

export default connect(mapStateToProps)(InventoryList);

const inventoryListColumns: TableColumn[] = [
    {
        name: 'description',
        active: true,
        text: 'Description',
        sortable: true,
    },
    {
        name: 'itemCode',
        active: true,
        text: 'Item Code',
        sortable: true,
    },
    {
        name: 'status',
        active: true,
        text: 'Status',
        sortable: false,
    },
    {
        name: 'brand',
        active: true,
        text: 'Brand',
        sortable: true,
    },
    {
        name: 'supplier',
        active: true,
        text: 'Supplier',
        sortable: true,
    },
    {
        name: 'cost',
        active: true,
        text: 'Cost',
        sortable: true,
    },
    {
        name: 'retail',
        active: true,
        text: 'Retail Price',
        sortable: true,
    },
];
